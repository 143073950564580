import React from 'react';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

import Links from '../Socials/Links';
import useTranslate from '../../hooks/useTranslate';

import { ReactComponent as Light } from './img/light.svg';
import { ReactComponent as Dark } from './img/dark.svg';

import {
  mainNavs,
} from './constants';

import './HeaderInfo.scss';

const HeaderInfo = ({ handleSwitchTheme }) => {
  const { t } = useTranslate();
  const duration = Math.ceil(dayjs.duration(Date.now() - new Date('Feb 24 2022 05:00:00').getTime()).asDays());

  return (
    <>
      <Links isHeader={true} className="headerNavSocial headerNavSocialTop" />
      <div className="headerDuration">{t('main.duration', { duration })}</div>
      <div className="headerTheme" onClick={() => handleSwitchTheme()}>
        <div className="headerThemeSwitch">
          <Light className="headerThemeLight" />
          <Dark className="headerThemeDark" />
        </div>
      </div>
      <div className="headerMobileNav">
        {mainNavs.map(nav => (
          <NavLink key={nav.label} to={nav.link} className="headerMobileNavItem">{t(nav.label)}</NavLink>
        ))}
      </div>
    </>
  );
};

export default HeaderInfo;
